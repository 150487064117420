var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"wrap__content",class:{ 'wrap__content--preloader': !_vm._getFishes || !_vm.application }},[(!_vm._getFishes || !_vm.application)?_c('v-preloader',{attrs:{"message":_vm.errorMessage}}):_vm._e(),(_vm._getFishes && _vm.application)?_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xl-2 col-lg-3"},[_c('v-sidebar',{attrs:{"active":['PurchasedManagement', 'TradeManagementMain']}})],1),_c('div',{staticClass:"col-xl-10 col-lg-9"},[_c('div',{staticClass:"content-wrapper"},[_c('div',{staticClass:"card__content"},[_c('div',{staticClass:"card__content-header"},[_c('div',{staticClass:"content__title"},[_c('div',{staticClass:"content__page--back"},[_c('a',{on:{"click":function($event){return _vm.$router.go(-1)}}},[_c('svg',{attrs:{"width":"21","height":"21","viewBox":"0 0 21 21","fill":"none","xmlns":"http://www.w3.org/2000/svg","svg-inline":'',"role":'presentation',"focusable":'false',"tabindex":'-1'}},[_c('path',{attrs:{"d":"M13.125 5.25l-5.25 5.25 5.25 5.25","stroke":"#52A5FC","stroke-width":"1.5","stroke-linecap":"round","stroke-linejoin":"round"}})])])]),_vm._m(0)])]),_c('div',{staticClass:"card__content--body"},[_c('form',{attrs:{"method":"POST"}},[_c('div',{staticClass:"form__line"},[_vm._m(1),_c('div',{staticClass:"form__line--right"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xl-5"},[_c('div',{staticClass:"form__line--input"},[_c('input',{staticClass:"input-linck",attrs:{"type":"text","required":"","name":"catch_at","disabled":"","placeholder":"Дата улова"},domProps:{"value":_vm.application.type == 'interior' ? 'Внутренний рынок' : 'Импорт'}})])])])])]),_c('div',{staticClass:"form__line"},[_vm._m(2),_c('div',{staticClass:"form__line--right"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xl-5"},[_c('div',{staticClass:"form__line--input",class:[
														_vm.errorMessage.messages && _vm.errorMessage.messages.catch_at
															? 'input--error'
															: '',
													]},[_c('input',{staticClass:"input-linck",attrs:{"type":"text","required":"","name":"catch_at","disabled":"","placeholder":"Дата улова"},domProps:{"value":_vm._f("formatDate")(_vm.application.catch_at)}})]),(_vm.errorMessage.messages && _vm.errorMessage.messages.catch_at)?_c('div',{staticClass:"input-required"},_vm._l((_vm.errorMessage.messages.catch_at),function(massage,massageInd){return _c('p',{key:massageInd},[_vm._v(" "+_vm._s(massage)+" ")])}),0):_vm._e()])])])]),_c('hr',{staticClass:"form__line"}),(_vm.application.type == 'interior')?_c('div',[_c('div',{staticClass:"form__line"},[_vm._m(3),_c('div',{staticClass:"form__line--right"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xl-5"},[_c('div',{staticClass:"form__line--input",class:[
															_vm.errorMessage.messages && _vm.errorMessage.messages.company
																? 'input--error'
																: '',
														]},[_c('input',{ref:"company",staticClass:"input-linck",attrs:{"type":"text","required":"","name":"company","disabled":"","placeholder":_vm.$t('headers.name')},domProps:{"value":_vm.application.company}})]),(_vm.errorMessage.messages && _vm.errorMessage.messages.company)?_c('div',{staticClass:"input-required"},_vm._l((_vm.errorMessage.messages.company),function(massage,massageInd){return _c('p',{key:massageInd},[_vm._v(" "+_vm._s(massage)+" ")])}),0):_vm._e()])])])]),_c('div',{staticClass:"form__line"},[_vm._m(4),_c('div',{staticClass:"form__line--right"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xl-5"},[_c('div',{staticClass:"form__line--input",class:[
															_vm.errorMessage.messages && _vm.errorMessage.messages.reference
																? 'input--error'
																: '',
														]},[_c('input',{ref:"reference",staticClass:"input-linck",attrs:{"type":"text","required":"","name":"reference","disabled":"","value":"","placeholder":"Номер"},domProps:{"value":_vm.application.reference}})]),(_vm.errorMessage.messages && _vm.errorMessage.messages.reference)?_c('div',{staticClass:"input-required"},_vm._l((_vm.errorMessage.messages.reference),function(massage,massageInd){return _c('p',{key:massageInd},[_vm._v(" "+_vm._s(massage)+" ")])}),0):_vm._e()])])])])]):_vm._e(),(_vm.application.type == 'import')?_c('div',[_c('div',{staticClass:"form__line"},[_vm._m(5),_c('div',{staticClass:"form__line--right"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xl-5"},[_c('div',{staticClass:"form__line--input",class:[
															_vm.errorMessage.messages && _vm.errorMessage.messages.country_id
																? 'input--error'
																: '',
														]},[_c('input',{ref:"country_id",staticClass:"input-linck",attrs:{"type":"text","required":"","name":"country_id","disabled":"","placeholder":_vm.$t('headers.name')},domProps:{"value":_vm.application.country_id}})]),(_vm.errorMessage.messages && _vm.errorMessage.messages.country_id)?_c('div',{staticClass:"input-required"},_vm._l((_vm.errorMessage.messages.country_id),function(massage,massageInd){return _c('p',{key:massageInd},[_vm._v(" "+_vm._s(massage)+" ")])}),0):_vm._e()])])])]),_c('div',{staticClass:"form__line"},[_vm._m(6),_c('div',{staticClass:"form__line--right"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xl-5"},[_c('div',{staticClass:"form__line--input",class:[
															_vm.errorMessage.messages && _vm.errorMessage.messages.company
																? 'input--error'
																: '',
														]},[_c('input',{ref:"company",staticClass:"input-linck",attrs:{"type":"text","required":"","name":"company","disabled":"","value":"","placeholder":_vm.$t('headers.name')},domProps:{"value":_vm.application.company}})]),(_vm.errorMessage.messages && _vm.errorMessage.messages.company)?_c('div',{staticClass:"input-required"},_vm._l((_vm.errorMessage.messages.company),function(massage,massageInd){return _c('p',{key:massageInd},[_vm._v(" "+_vm._s(massage)+" ")])}),0):_vm._e()])])])]),_c('div',{staticClass:"form__line"},[_vm._m(7),_c('div',{staticClass:"form__line--right"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xl-5"},[_c('div',{staticClass:"form__line--input",class:[
															_vm.errorMessage.messages && _vm.errorMessage.messages.certificate_sender
																? 'input--error'
																: '',
														]},[_c('input',{ref:"certificate_sender",staticClass:"input-linck",attrs:{"type":"text","required":"","name":"certificate_sender","disabled":"","placeholder":"Номер"},domProps:{"value":_vm.application.certificate_sender}})]),(
															_vm.errorMessage.messages && _vm.errorMessage.messages.certificate_sender
														)?_c('div',{staticClass:"input-required"},_vm._l((_vm.errorMessage.messages
																.certificate_sender),function(massage,massageInd){return _c('p',{key:massageInd},[_vm._v(" "+_vm._s(massage)+" ")])}),0):_vm._e()])])])]),_c('div',{staticClass:"form__line"},[_vm._m(8),_c('div',{staticClass:"form__line--right"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xl-5"},[_c('div',{staticClass:"form__line--input",class:[
															_vm.errorMessage.messages && _vm.errorMessage.messages.certificate_interior
																? 'input--error'
																: '',
														]},[_c('input',{ref:"certificate_interior",staticClass:"input-linck",attrs:{"type":"text","required":"","name":"certificate_interior","disabled":"","placeholder":"Номер"},domProps:{"value":_vm.application.certificate_interior}})]),(
															_vm.errorMessage.messages && _vm.errorMessage.messages.certificate_interior
														)?_c('div',{staticClass:"input-required"},_vm._l((_vm.errorMessage.messages
																.certificate_interior),function(massage,massageInd){return _c('p',{key:massageInd},[_vm._v(" "+_vm._s(massage)+" ")])}),0):_vm._e()])])])])]):_vm._e(),_c('hr',{staticClass:"form__line"}),_c('div',{staticClass:"form__line"},[_c('div',{staticClass:"form__line--left"},[_c('div',{staticClass:"form__line--title"},[_vm._v(" "+_vm._s(_vm.$t('headers.composition_catch_fish'))+" ")])]),_c('div',{staticClass:"form__line--right"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xl-7"},_vm._l((_vm.formList.fish),function(fish,fishIndex){return _c('div',{key:fishIndex,staticClass:"quota-fish__line"},[_c('div',{staticClass:"form__line--input"},[_c('div',{staticClass:"row align-items-center"},[_c('div',{staticClass:"col-xl-10"},[_c('div',{staticClass:"quota-fish__item"},[_c('div',{staticClass:"row align-items-center"},[_c('div',{staticClass:"col-xl-7"},[_c('div',{staticClass:"form__line--input"},[_c('input',{staticClass:"input-linck",attrs:{"type":"text","disabled":""},domProps:{"value":fish.name}})])]),_c('div',{staticClass:"col-xl-5"},[_c('div',{staticClass:"form__line--input"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(fish.value),expression:"fish.value"}],staticClass:"input-linck",attrs:{"type":"number","step":".01","min":"0","disabled":"","placeholder":"0"},domProps:{"value":(fish.value)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(fish, "value", $event.target.value)}}})])])])])])])])])}),0)])])])])])])])])])]):_vm._e()],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content__title--element"},[_c('div',{staticClass:"content__title--text"},[_vm._v("Журнал учета закупленной рыбы")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form__line--left"},[_c('div',{staticClass:"form__line--title"},[_vm._v("Внутренний рынок/Импорт")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form__line--left"},[_c('div',{staticClass:"form__line--title"},[_vm._v("Дата закупки")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form__line--left"},[_c('div',{staticClass:"form__line--title"},[_vm._v("Наименование организации")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form__line--left"},[_c('div',{staticClass:"form__line--title"},[_vm._v("Номер справки о происхождении")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form__line--left"},[_c('div',{staticClass:"form__line--title"},[_vm._v("Страна импорта")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form__line--left"},[_c('div',{staticClass:"form__line--title"},[_vm._v("Наименование организации")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form__line--left"},[_c('div',{staticClass:"form__line--title"},[_vm._v(" Номер вет. сертификата"),_c('br'),_vm._v("(страна Отправитель) ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form__line--left"},[_c('div',{staticClass:"form__line--title"},[_vm._v(" Номер вет. сертификата"),_c('br'),_vm._v("(Внутренний) ")])])
}]

export { render, staticRenderFns }